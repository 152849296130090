<template>
  <tr :class="{'finances__row--negatived' : props.item.negatived}">
    <wi-list-data-table-actions
      :config="config"
      :props="props"
    ></wi-list-data-table-actions>
    <td class="justify-left">
      {{props.item.id}}
    </td>   
    <td class="justify-center" :style="{color: props.item.in_out == 1 ? '#4A925F' : '#C62828'}">
      <div v-if="props.item.in_out == 1" style="text-align: center;">
        <v-icon>get_app</v-icon> <br>
        <span>Receita</span>
      </div>
      <div v-if="props.item.in_out == 2" style="text-align: center;">
        <v-icon>publish</v-icon> <br>
        <span>Despesa</span>
      </div>
    </td>
    <td class="justify-center">
      <strong v-if="props.item.parcel" class="finance_parcel parcel">
        <span> {{ props.item.number_this }}/{{ props.item.number_total }} </span>
      </strong>
      <span v-else-if="props.item.recurrence" class="finance_recurrence finance_recurrence--recurrence" :class="[`finance_recurrence--${props.item.recurrence.frequency_id}`]">
        <span v-if="props.item.recurrence.frequency_id == 1">Mensal</span>
        <span v-else-if="props.item.recurrence.frequency_id == 5">Anual</span>
      </span>
      <span v-else class="finance_parcel">
        <span> Única </span>
      </span>
    </td>
    <td class="justify-left">
      <strong style="font-size: 16px;">
        {{ props.item.amount_total | money }}
      </strong>
    </td> 
    <td class="justify-left" style="max-width: 90px;">
      <strong>
        {{ props.item.date_receive_format }}
      </strong><br>
      <span style="color: gray;">
        ({{ props.item.date_competence_format }})
      </span>
    </td>
    <td class="justify-center" style="text-align: center;">
      <div v-if="props.item.status == 0" class="finance_status canceled">
        <strong>Cancelado</strong>
      </div>
      <strong v-if="props.item.status == 1">
        <div v-if="props.item.date_validity_expired" class="finance_status expired">
          <span>Atrasado</span>
          <div class="finances__status-days">({{props.item.date_validity_days * -1}} dias)</div>
        </div>
        <div v-else class="finance_status unpaid">
          <span>Aguardando</span>
        </div>
      </strong>
      <div v-if="props.item.status == 2" class="finance_status paid">
        <span>
          <v-icon small>done</v-icon>
          <strong>Pago</strong>
        </span>
      </div>
      <div v-if="props.item.status == 4" class="finance_status unpaid">
        <strong>Aguardando Venda</strong>
      </div>
    </td>
    <td class="justify-center">
      <span v-if="props.item.description"> {{ props.item.description || ' - - - ' }} </span>
      <strong v-if="props.item.person && props.item.person.name">
        <br v-if="props.item.description">{{ props.item.person.name }}
      </strong>
      <span v-if="!props.item.description && !props.item.person"> - - - </span>
    </td>
    <td class="justify-center" style="text-align: center;">
      <div v-if="props.item.finance_form_payment_id && props.item.form_payment">
        <v-icon :color="props.item.form_payment.color">{{props.item.form_payment.icon}}</v-icon> <br>
        <span>{{props.item.form_payment.name}}</span>
      </div>
      <div v-else>Indefinido</div>
    </td>
    <td class="justify-left">
      <strong v-if="props.item.category">
        {{ props.item.category.name }}
      </strong>
      <br v-if="props.item.category && props.item.account">
      <span v-if="props.item.account">
        {{ props.item.account.name }}
      </span>
      <span v-if="!props.item.category && !props.item.account"> - - - </span>
    </td>
    <td class="justify-center">
      <v-menu bottom left>
        <v-btn slot="activator" icon>
          <v-icon>more_vert</v-icon>
        </v-btn>
        <v-list>
          <v-list-tile v-if="showPaymentOption" @click="paymentAction()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="grey lighten-1">payment</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title v-if="props.item.payments_count > 0"> 
              Ver Boleto(s)
            </v-list-tile-title>
            <v-list-tile-title v-else> 
              <span v-if="props.item.date_validity_expired">
                Gerar 2° Via
              </span>
              <span v-else>
                Gerar Boleto
              </span>
            </v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile v-prms="{ id: internetPermission }" @click="sendFinanceToEmail()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>email</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Enviar por e-mail</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile v-prms="{ id: internetPermission }" @click="listInvoices()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>request_quote</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Listar Notas Fiscais</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile v-prms="{ id: internetPermission }" @click="generateInvoices()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>request_quote</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Emitir Nova Nota Fiscal</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="$WiDialog(wiDialogs.events(props.item))">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>message</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Visualizar Anotações</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="$WiDialog(wiDialogs.files(props.item))">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>attachment</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Arquivos/Anexos</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>
<script>
  import { PERMISSION_INTERNET_MODULE } from '@/default/constants/permissions'
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Finances',
    data () {
      return {
        wiDialogs: {
          files: (finance) => {
            return {
              title: 'Arquivos/Anexos',
              icon: 'attachment',
              component: {
                content: () => import('@/default/component/WiFile/WiFileList'),
                props: {
                  index: 'finance-fileList',
                  api: {
                    url: 'finance/file',
                    params: '?order=id,desc&where=finance_id,' + finance.id
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Arquivo',
                action: () => {
                  this.$WiDialog({
                    title: 'Arquivos/Anexos ',
                    icon: 'attachment',
                    component: {
                      content: () => import('@/default/component/WiFile/WiFile'),
                      props: {
                        finance_id: finance.id
                      }
                    },
                    onClose: () => {
                      this.$WiView('finance-fileList').refresh()
                    }
                  })
                }
              }
            }
          },
          events: (finance) => {
            return {
              title: 'Anotações/Eventos ',
              icon: 'message',
              component: {
                content: () => import('@/default/component/WiTimeLine/WiTimeLine'),
                props: {
                  index: 'finance-event',
                  api: {
                    url: 'finance/event',
                    params: '?order=id,desc&where=finance_id,' + finance.id
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Nota',
                action: () => {
                  this.$WiEditDialog({
                    wiConfig: 'finance-event',
                    onSubmit: () => {
                      this.$WiView('finance-event').refresh()
                    },
                    data: {finance_id: finance.id}
                  })
                }
              }
            }
          }
        }
      }
    },
    computed: {
      showPaymentOption () {
        return this.props.item.in_out === 1
      },
      internetPermission () {
        return PERMISSION_INTERNET_MODULE
      }
    },
    methods: {
      paymentAction: function () {
        if (this.props.item.payments_count > 0) {
          this.listFinancePayments()
        } else if (this.props.item.date_validity_expired) {
          this.createFinancePaymentCustom()
        } else {
          this.createFinancePayment()
        }
      },
      createFinancePayment: function () {
        this.$WiApiPost({
          uri: 'finance/payment',
          data: {finance_id: this.props.item.id},
          callback: () => {
            this.listFinancePayments()
            this.config.refresh()
          },
          config: {
            msgAsk: {
              title: this.props.item.date_validity_expired ? 'Gerar segunda via?' : 'Gerar boleto?',
              text: this.props.item.date_validity_expired ? 'A data de vencimento será ajustada' : 'Um boleto será criado, baseado nos dados desta movimentação'
            },
            msgLoad: true,
            msgSucess: true
          }
        })
      },
      createFinancePaymentCustom: function () {
        this.$WiEditDialog({
          wiConfig: 'finance-payment-create',
          data: {
            finance_id: this.props.item.id
          },
          onSubmit: () => {
            this.listFinancePayments()
            this.config.refresh()
          }
        })
      },
      listFinancePayments: function () {
        this.$WiListDialog({
          wiConfig: 'finance-payment',
          filter: {
            finance_id: this.props.item.id
          }
        })
      },
      sendFinanceToEmail: function () {
        this.$WiEditDialog({
          wiConfig: 'finance-send-email',
          data: {
            finance_id: this.props.item.id,
            email: this.props.item?.person?.email
          },
          onSubmit: () => {
            this.config.refresh()
          }
        })
      },
      listInvoices: function () {
        this.$WiListDialog({
          wiConfig: 'invoice',
          filter: {
            finance_id: this.props.item.id
          }
        })
      },
      generateInvoices: function () {
        const financeId = this.props.item.id
        this.$WiApiPost({
          uri: `finance/${financeId}/invoice/generate`,
          data: {
            finance_id: financeId
          },
          callback: (test) => {
            console.log(test)
            this.listInvoices()
            this.config.refresh()
          },
          config: {
            msgAsk: {
              title: 'Gerar Nota Fiscal?',
              text: 'Uma nova nota fiscal será emitida. Essa ação não poderá ser desfeita!'
            },
            msgLoad: true,
            msgSucess: true,
            msgError: {
              title: 'Ocorreu um erro ao gerar Nota Fiscal',
              text: 'Geralmente este erro acontece quando os dados do cliente estão incorretos ou incompletos!'
            },
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
.finance_status {
  border-width: 1px;
  border-style: solid;
  padding: 0px;
  max-width: 80px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.finance_parcel {
  border-width: 1px;
  border-style: solid;
  padding: 0px;
  max-width: 58px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 0;
}
.finance_recurrence {
  border-width: 1px;
  border-style: solid;
  padding: 0px;
  max-width: 58px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 0;
}
.finance_recurrence--1 {
  background: #c3e6f4;
  color: #0066ff;
}
.finance_recurrence--5 {
  background: #7f95f3;
  color: #0030ce;
}
.canceled {
  color: grey;
  background: #eceff1;
}
.unpaid {
  color: #ff6d00;
  background: #f0f4c3;
}
.expired {
  color: #F44336;
  background: #ffe7e5;
}
.paid {
  color: green;
  background: #b9f6ca;
}
.parcel {
  color: white;
  background: #000;
  min-width: 44px !important;
  width: 44px !important;
}
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
.finances__status-days {
  font-size: 12px;
  min-width: 70px;
}
.finances__row--negatived {
  background: black;
  color: white !important;
}
.finances__row--negatived .v-icon {
  color: white !important;
}
</style>